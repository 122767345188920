import React from 'react'

import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import ServicesMenu from './_menu.js'
import PropTypes from 'prop-types'
import NakaLightbox from '../../components/NakaLightbox'

const MerchandisePage = ({ data }) => (
  <Layout bgClass="services">
    <ServicesMenu pageName="merch" />

  <div className="textPageDetails">
  <p>
    In the past Nakatomi has helped design and produce everything from t-shirts and hats to enamel pins and letterpress calendars. Send a finished design or get something custom made by Nakatomi’s in-house designers or wide network of outside artists. Have plans for a Kickstarter that needs merchandise? Let us know.
  </p>
  </div>

  <div className="gallery-container small-gallery-3">
  <NakaLightbox images={data.allFile.edges} delimiter=" -- " />
  </div>

</Layout>)

MerchandisePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MerchandisePage

export const pageQuery = graphql`
  query MerchPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/services/merchandise/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
